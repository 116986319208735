.ant-select-selector {
    border: vw(1px) solid #797979;
    border-radius: vw(4px) !important;
}

.ot-select-error {
    .ant-select-selector {
        @apply border-red-600 #{!important}
    }

    .ant-select-selection-placeholder {
        @apply text-red-200 #{!important}
    }
}

.ot-transparent {
    .ant-select-selector {
        border: none !important;
        color: $primary !important;
        padding-left: 0 !important;
        background: transparent !important;
    }

    .ant-select-arrow {
        display: none !important;
    }

}
.login-container {
  height: vw(800px);

  //section one
  .section-one {
    width: vw(838px);
    border-radius: 0px;
    align-items: center;

    background-image: url("../../../images/auth/background.jpg");
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-size: cover;

    .logo-section {
      img {
        width: vw(169px);
        height: vw(169px);
        margin-top: vw(273px);
      }
    }

    .quote-section {
      margin-top: vw(62px);
      margin-bottom: vw(119px);

      h1 {
        height: vw(42px);
        border-radius: vw(0px);
        font-size: vw(30px);
        color: #002244;
      }

      p {
        margin-top: vw(18px);
        width: vw(501px);
        height: vw(61px);
        border-radius: vw(0px);
        font-size: vw(18px);
        text-align: CENTER;
        color: #002244;
      }
    }

    .footer-section {
      border-radius: 0px;
      font-size: vw(12px);
      font-family: Lato, Lato-Normal;
      font-weight: normal;
      text-align: LEFT;
      color: #ffffff;
      line-height: vw(14px);
    }
  }

  //section two
  .section-two {
    width: vw(442px);
    background: #ffffff;
    border-radius: 0px;
    padding-right: vw(55px);
    padding-left: vw(38px);
    justify-content: center;

    h1 {
      margin-top: vw(74px);
      width: vw(150px);
      height: vw(34px);
      border-radius: vw(0px);
      font-size: vw(24px);
      // font-family: Microsoft YaHei, Microsoft YaHei-Normal;
      font-weight: normal;
      text-align: LEFT;
      color: #002244;
    }

    //login form

    .login-form {
      margin-top: vw(34px);

      .text-box {
        width: vw(349px);
        height: vw(40px); //59
        background: #ffffff;
        border: vw(1px) solid #dfdfdf;
        border-radius: vw(5px);
        padding: vw(10px);
        // margin-bottom: vw(34px);
      }

      .check-box {
        width: vw(17px);
        height: vw(17px);
        margin-right: vw(10px);
        border-radius: 50%;
      }

      span {
        // width: vw(113px);
        // height: vw(17px);
        font-size: vw(16px);
        // font-family: Microsoft YaHei, Microsoft YaHei-Normal;
        // font-weight: normal;
        text-align: left;
        color: #a3a3a3;
        line-height: vw(22px);
      }

      button {
        width: vw(140px);
        height: vw(50px);
        margin-top: vw(20px);
        font-size: vw(18px);
        text-align: center;
        color: #fffafa;
        line-height: vw(24px);
        background-color: #69be29;
        border: none;
        border-radius: vw(5px);


        .ant-spin-dot {
          color: #fffafa !important;
          margin-right: vw(8px);
        }
      }

      .forget-text {
        width: vw(119px);
        height: vw(20px);
        border-radius: 0px;
        font-size: vw(14px);
        text-align: LEFT;
        color: #002244;
      }

      .line {
        width: vw(361px);
        height: 0px;
        border: vw(1px) solid #dfdfdf;
      }

      p {
        width: vw(126px);
        height: vw(25px);
        border-radius: 0px;
        font-size: vw(18px);
        text-align: LEFT;
        color: #a3a3a3;
      }

      .social-login-section {
        img {
          width: vw(30px);
          height: vw(30px);
          border-radius: 0px;
        }

        p {
          width: vw(109px);
          border-radius: 0px;
          font-size: vw(16px);
          text-align: LEFT;
          color: #002244;
        }
      }
    }

    .forget-password-form {
      .text-box {
        width: vw(349px);
        height: vw(59px);
        background: #ffffff;
        border: vw(1px) solid #dfdfdf;
        border-radius: 0px;
        padding: vw(10px);
        margin-top: vw(26px);
      }

      button {
        width: vw(140px);
        height: vw(50px);
        margin-top: vw(20px);
        font-size: vw(18px);
        text-align: center;
        color: #fffafa;
        line-height: vw(24px);
        background-color: #00a619;
        border: none;
        border-radius: vw(5px);
      }

      .line {
        width: vw(361px);
        height: 0px;
        border: vw(1px) solid #dfdfdf;
      }

      p {
        width: vw(126px);
        height: vw(25px);
        border-radius: 0px;
        font-size: vw(18px);
        text-align: LEFT;
        color: #a3a3a3;
      }

      .social-login-section {
        img {
          width: vw(30px);
          height: vw(30px);
          border-radius: 0px;
        }

        p {
          width: vw(109px);
          border-radius: 0px;
          font-size: vw(16px);
          text-align: LEFT;
          color: #002244;
        }
      }
    }

    // Forget password

    .forget-password-form-container {
      h1 {
        margin-top: vw(120px);
        width: vw(192px);
        height: vw(34px);
        border-radius: 0px;
        font-size: vw(24px);
        text-align: LEFT;
        color: #002244;
      }

      h3 {
        width: vw(335px);
        height: vw(40px);
        border-radius: 0px;
        font-size: vw(14px);
        margin-bottom: 0px;
        text-align: LEFT;
        color: #676a6c;
        line-height: vw(20px);
        margin-top: vw(23px);
      }

      button {
        width: vw(349px);
        height: vw(59px);
        background-color: #69be29;
        color: #fffafa;
        line-height: vw(24px);
        font-size: vw(18px);
      }
    }
  }
}
.admin-widget-box-container {
  // width: vw(1231px);
  background: #ffffff;
  border-radius: 0px;
  // height: vw(103px);
  padding: vw(20px) vw(24px) 0 vw(15px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .box-container {
    width: vw(150px);
    height: vw(66px);
    border: vw(1px) solid #d8d8d8;
    background-color: #eeeeee;
    margin-bottom: vw(17px);

    .box-title-section {
      width: vw(150px);
      height: vw(20px);
      border-radius: 0px;
      font-size: vw(12px);
      text-align: LEFT;
      color: #ffffff;
      // background-color: #002244;
      padding: vw(5px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: vw(5px);
      padding-right: vw(5px);
    }

    .box-count-section {
      display: flex;
      align-items: center;
      color: #151515;
      padding: vw(5px);
      font-size: vw(22px);
    }
  }
}
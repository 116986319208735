.top-navbar-container {
  // width: vw(1231px);
  width: 100%;
  height: vw(36px);
  background: #ffffff;
  border-radius: 0px;
  font-size: vw(14px);
  text-align: CENTER;
  color: #333333;
  line-height: vw(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: vw(5px) vw(24px) vw(5px) vw(5px);

  .left-section {
    display: flex;
    align-items: center;

    .menu-icon {
      width: vw(25px);
      height: vw(25px);
      background: #dcdcdc;
      border-radius: vw(2px);
      text-align: center;
      color: darkgray;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;

      .material-icons {
        color: darkgray;
        font-size: 18px;
      }
    }

    p {
      width: vw(50px);
      height: vw(17px);
      font-size: vw(14px);
      color: #002244;
      line-height: vw(20px);
      margin-left: vw(10px);
      margin-bottom: 0;
    }
  }

  .right-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .material-icons {
      color: darkgray;
      font-size: 25px;
      margin-left: vw(20px);
    }
  }
}

.top-navbar-container-extend {
  // transition: 0.5s;
  // transition-timing-function: ease-in;
  width: vw(1095px);
  height: vw(36px);
  background: #ffffff;
  border-radius: 0px;
  font-size: vw(14px);
  text-align: CENTER;
  color: #333333;
  line-height: vw(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: vw(5px) vw(24px) vw(5px) vw(5px);

  .left-section {
    display: flex;
    align-items: center;

    .menu-icon {
      width: vw(25px);
      height: vw(25px);
      background: #dcdcdc;
      border-radius: vw(2px);
      text-align: center;
      color: darkgray;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: vw(10px);
      z-index: 9999;


      .material-icons {
        color: darkgray;
        font-size: 18px;
      }
    }

    p {
      width: vw(50px);
      height: vw(17px);
      font-size: vw(14px);
      color: #002244;
      line-height: vw(20px);
      margin-left: vw(10px);
      margin-bottom: 0;
    }
  }

  .right-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .material-icons {
      color: darkgray;
      font-size: 25px;
      margin-left: vw(20px);
    }
  }
}
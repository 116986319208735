@import "../src/assets/scss/variables";
@import "../src/assets/scss/mixins";
@import "../src/assets/scss/function";
@import "../src/assets/scss/typography";

/*=======================================================
            desktop components css
 =======================================================*/

@media screen and (min-width: 768px) {
  @import "../src/assets/scss/global";
  @import "../src/assets/scss/desktop/index";
}

/*=======================================================
            mobile components css
 =======================================================*/
@media screen and (max-width: 767px) {
  @import "../src/assets/scss/globalMobile";
  @import "../src/assets/scss/mobile/index";
}
/* ===============================================================
                    
                            variables

================================================================== */

// colors
$color-black: #000000;
$color-white: #ffffff;
$color-orange: #ff8f2e;
$color-blue: #56a5eb;
$color-yellow: #ffd800;
$primary:#69BE28;
.ant-slider {
    width: vw(143px);

}

.ant-slider-rail {
    background-color: #F0F0F0 !important;
}

.ant-slider-track {
    background-color: #69BE28 !important;
}

.ant-slider-handle {
    border: vw(2px) solid #69BE28 !important;
}
.font-15-regular {
    font-size: vw(15px);
    margin-bottom: 0;
    font-weight: 400;
}

.font-13-regular {
    font-size: vw(13px);
    margin-bottom: 0;
    font-weight: 400;
}
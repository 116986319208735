.footer-main-container {
  // width: vw(1231px);
  height: vw(31px);
  background: #c9c9c9;
  border-radius: 0px;
  display: flex;
  align-items: center;
  // position: absolute;
  position: sticky;
  bottom: 0;

  span {
    margin-left: vw(27px);
    width: vw(292px);
    height: vw(10px);
    border-radius: 0px;
    font-size: vw(11px);
    color: #007575;
    line-height: vw(10px);
  }
}
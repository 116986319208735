/* ===============================================================
                    
                    Global reset and css

================================================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: inherit !important;
  color: inherit !important;
}

li {
  list-style-type: none;
}

button:focus,
input:focus,
textarea:focus,
a:focus {
  outline: 0 !important;
  border: none;
}

.outline-0 {
  outline: 0;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0;
}

*::placeholder {
  color: #848484;
  font-weight: 400;
  font-family: Raleway-Medium, sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-border-div input:disabled {
  opacity: 0.3;
}

// scrollbar chrome
*::-webkit-scrollbar {
  height: mvwDivide(5px);
  width: mvwDivide(5px);
}

*::-webkit-scrollbar-track,
*::-ms-scrollbar-track-color {
  margin: mvwDivide(10px);
  background: #d9d1f9;
  box-shadow: 0 0 mvwDivide(6px) rgba(0, 0, 0, 0.16);
  border-radius: mvwDivide(35px);
}

*::-webkit-scrollbar-thumb {
  border-radius: mvwDivide(30px);
  background: rgba(112, 248, 78, 0.8);

  &:hover {
    background: rgba(63, 201, 29, 0.8);
  }
}

html,
body {
  overflow-x: hidden !important;
  font-size: mvwDivide(16px) !important;
  color: $color-black !important;
  font-family: Raleway-Medium, sans-serif, Helvetica !important;
  background-color: $color-white;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.MuiTableCell-sizeMedium {
    padding: vw(10px) vw(10px) !important;
    font-size: vw(12px) !important;
}

.MuiTableCell-head {
    padding: vw(12px) vw(10px) !important;
    font-size: vw(12px) !important;
    font-weight: 700 !important;
}

.MuiTablePagination-selectLabel {
    margin-bottom: 0;
}

.MuiTablePagination-selectIcon {
    width: 0.7em !important;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.MuiTablePagination-actions {
    color: $primary;
}

.Mui-disabled {
    color: $primary !important;
    opacity: 0.5;
}

.ant-pagination-prev,
.ant-pagination-next {
    .anticon {
        color: $primary;
    }
}

.ant-pagination-simple .ant-pagination-simple-pager {
    // width: vw(130px);

    input {
        width: vw(30px);
        background-color: transparent !important;
        border: none !important;
    }
}

.ant-table-column-sorter {
    margin-right: vw(20px);
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: $primary !important;
}

.ant-table-column-title {
    font-size: vw(13px);
    font-weight: 700 !important;
    word-break: break-all;
}

.ant-table-pagination {
    padding-right: vw(40px) !important;
}


// .ant-table-column-title {
// @apply py-3 px-2;
// }

.ant-table-thead {
    th {
        padding: vw(10px) !important;
        cursor: pointer;
        font-weight: 700 !important;
        font-size: vw(13px) !important;
    }
}

.ant-table-cell {
    padding: 0 !important;
}

.opportunitiesExpandedTable {

    .ant-table-column-title {
        font-size: vw(10px);
        padding: vw(5px);
    }


    .ant-table-column-sorter {
        margin-right: vw(5px);
    }
}

.opportunities-table-expanded {
    width: 100%;

    .ant-table-pagination {
        position: absolute;
        right: vw(10px);
        z-index: 999;
        top: vw(-55px);
    }
}
.status-box-container {
  //   width: vw(1231px);
  height: vw(135px);
  background: #ffffff;
  border-radius: 0px;
  padding: vw(15px);

  .date-section {
    position: absolute;
    right: 16px;

    .prev-next-text {
      width: vw(42px);
      height: vw(18px);
      border-radius: 0px;
      font-size: vw(12px);
      text-align: left;
      color: $primary;
    }

    .date-text {
      width: vw(79px);
      height: vw(17px);
      border-radius: 0px;
      font-size: vw(12px);
      text-align: left;
      color: #007575;
      margin-left: vw(4px);
      margin-right: vw(4px);
    }
  }

  .react-tabs__tab--selected {
    background: none;
    border-bottom: vw(4px) solid $primary;
    border-top: none;
    border-right: none;
    border-left: none;
    color: black;
  }

  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0;
  }

  .react-tabs__tab {
    font-size: vw(12px);
    text-align: LEFT;
    color: #151515;
    line-height: vw(18px);
    border: none;
  }

  .react-tabs__tab:focus:after {
    background: $primary;
  }

  .react-tabs__tab-panel {
    display: flex;
  }
}

.small-status-box {
  width: vw(135px);
  height: vw(66px);
  border: vw(1px) solid #d8d8d8;
  margin-right: vw(17px);
  margin-top: vw(13px);
  background: #EEEEEE;
}
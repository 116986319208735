.manage-main-container {
  // height: vw(900px);
  min-height: 100vh;
}

.selectedMetrix {
  border-radius: vw(2px);
  background: #002244;
  color: white;
}

.unSelectedMetrix {
  border-radius: vw(2px);
  border: vw(1px) solid #797979;
}
.sideBar {
  position: fixed;
  top: 0;
  min-height: 100vh;
}

.expandedBlock,
.unExpandedBlock {
  transition: padding-left .5s ease-in-out;
}

.unExpandedBlock {
  // transition: 3s linear ease-in;

  .content-section-minimize {
    padding-left: vw(50px);
  }
}

.expandedBlock {

  .content-section-extend,
  .content-section-minimize {
    padding-left: vw(185px);

  }
}

.content-section-extend,
.content-section-minimize {
  width: 100%;
  transition: padding 0.2s ease-out;
}

// .unExpandedBlock {
//   position: relative;
//   padding-left: 100px;
// }
.extended-sidebar-section {
  width: vw(185px);
  background: #002244;
  border-radius: 0px;
  font-size: vw(14px);
  text-align: CENTER;
  color: #333333;
  line-height: 20px;
  // transition: width 1s;
  // position: absolute;
  position: fixed;
  z-index: 9999;

  .logo-section {
    margin-top: vw(8px);
    color: #fff;
    opacity: 1;
  }


  // menu items

  .menu-section {
    margin-top: vw(56px);

    .navlink {
      width: 100%;
      display: flex;
      padding-left: vw(10px);
    }

    .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: vw(29px);
      color: #fff;
      margin-bottom: vw(26px);
      // .material-icons-round {
      //   margin-top: vw(4px);
      // }
    }

    .active-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      // border-left: vw(5px) solid $primary;
      width: 100%;
      height: vw(29px);
      color: #fff;
      margin-bottom: vw(26px);
      // .material-icons-round {
      //   margin-right: vw(6px);
      //   margin-top: vw(4px);
      // }
    }
  }

  .material-icons-round {
    font-size: vw(20px);
  }

  .material-icons {
    transition: 0.5s;
    transition-timing-function: ease-in;
    margin-top: vw(36px);
    font-size: vw(35px);
  }
}

.menu-title {
  text-align: left;
  padding-left: vw(9px);
  opacity: 1;
  // transition: 0.5s;
  // transition-timing-function: ease-in;
  // transition: all 1.5s ease-out;
  transition-delay: 0.5s;
  height: vw(20px);
  border-radius: 0px;
  font-size: vw(14px);
  color: #ffffff;
}

.menu-title-hidden {
  text-align: left;
  opacity: 0;
  // transition: 0.1;
  // transition-timing-function: ease-out;
  width: vw(112px);
  height: vw(20px);
  border-radius: 0px;
  font-size: vw(14px);
  color: #ffffff;
}
.dashboard_sales_main_container {
  height: vw(1800px);

  .dashboard-content-div {
    margin-bottom: vw(50px);
  }
}

.dashboard-main-container {
  // width: 100%;

  .content-section-minimize {
    background-color: #eeeeee;
    display: flex;
    flex-flow: column;
    // width: vw(1231px);
  }

  .content-section-extend {
    background-color: #eeeeee;
    // width: vw(1231px);
    display: flex;
    flex-flow: column;
    // margin-left: 49px;
  }
}

.dashboard-content-div {
  padding: vw(19px) vw(18px) vw(20px) vw(14px);
}

.section-right {
  display: flex;

  p {
    width: vw(49px);
    height: vw(14px);
    border-radius: vw(0px);
    font-size: vw(12px);
    text-align: LEFT;
    color: $primary;
    margin: 0;
  }

  .material-icons {
    font-size: vw(16px);
    color: $primary;
  }
}

.dashboard-performance-section-line {
  height: vw(273px) !important;
}

.dashboard-performance-section {
  background-color: white;
  padding: vw(20px);
  border: vw(1px) solid #d8d8d8;
  border-radius: vw(3px);
  position: relative;
  height: vw(245px);

  &::after {
    position: absolute;
    width: 100%;
    height: vw(2px);
    background: $primary;
    content: "";
    left: 0;
    top: 0;
  }
}
.sidebar-collapse-section {
  width: vw(49px);
  background: #002244;
  border-radius: 0px;
  font-size: vw(14px);
  text-align: CENTER;
  color: #333333;
  line-height: 20px;
  // transition: width 3s;

  //logo
  .logo-section {
    margin-top: vw(8px);
    color: #fff;
  }

  // menu items

  .menu-section {
    margin-top: vw(93px);

    .navlink {
      width: 100%;
      display: flex;
      margin-left: vw(30px);
      transition: 2s;
    }

    .menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: vw(29px);
      color: #fff;
      margin-bottom: vw(26px);
    }

    .active-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      // border-left: vw(5px) solid $primary;
      width: 100%;
      height: vw(29px);
      color: #fff;
      margin-bottom: vw(26px);
    }
  }

  .material-icons-round {
    font-size: vw(20px);
  }

  .material-icons {
    transition: 0.5s;
    transition-timing-function: ease-in;
    font-size: vw(35px);
  }
}

.mui-menu {
  width: vw(19px) !important;
  height: vw(19px) !important;
}
.welcome-box-container {
  // width: vw(1231px);
  width: 100%;
  height: vw(40px);
  background: $primary;
  border-radius: 0px;
  display: flex;
  align-items: center;

  span {
    width: vw(684px);
    height: vw(18px);
    border-radius: 0px;
    font-size: vw(13px);
    color: #ffffff;
    margin-left: vw(20px);
  }
}

.welcome-box-container-extend {
  transition: 0.5s;
  transition-timing-function: ease-in;
  width: vw(1095px);
  height: vw(40px);
  background: $primary;
  border-radius: 0px;
  display: flex;
  align-items: center;

  span {
    width: vw(684px);
    height: vw(18px);
    border-radius: 0px;
    font-size: vw(13px);
    color: #ffffff;
    margin-left: vw(20px);
  }
}
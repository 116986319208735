.all-request-status-graph-container {
  padding-top: vw(19px);

  .graph-table-heading-text {
    width: vw(152px);
    height: vw(21px);
    border-radius: 0px;
    font-size: vw(15px);
    text-align: LEFT;
    color: #151515;
  }

  .heading-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: vw(12px);
  }

  .section-left {
    display: flex;

    .material-icons-round {
      font-size: vw(16px);
      margin-right: vw(5px);
    }
  }



  .graph-container {
    // width: vw(1199px);
    height: vw(315px);
    background: #ffffff;
    border: vw(1px) solid #d8d8d8;
    border-radius: 0px;
  }
}
// .feature-request-main-container {
//   height: vw(900px);
// }
.generateReportBtn--FR {
  display: flex;
  align-items: center;

  .anticon {
    line-height: 0 !important;
    font-size: 16px !important;
  }
}

.deleteIcon {
  .ant-spin-dot {
    color: green !important;
  }
}

.appendListModal {
  .ant-btn-primary {
    background: #69BE28 !important;
    border-color: #69BE28;
  }

  .ant-btn-default {
    border-color: #69BE28;
    color: #69BE28 !important;
  }
}

.submit-btn {

  .ant-spin {
    color: white !important;
  }
}
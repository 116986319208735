.ot-textarea {
    width: 100%;
    border: vw(1px) solid #797979;
    background: transparent;
    padding: vw(10px);
    font-size: vw(13px);

    &:focus {
        border: vw(1px) solid #797979;
    }

}

//================================================================
//                INPUT
//================================================================
.ot-input {
    @apply border-[1px] border-gray-400 disabled:border-0 disabled:bg-transparent disabled:text-primary #{
 !important
}
}

.ot-input--manage {
    border-left: none !important;
    border-top: none !important;
    border-right: none !important;
    position: relative;

    &:focus {
        box-shadow: none !important;

    }
}

.ot-input-error {
    @apply border-red-600 placeholder:text-red-200 #{!important}
}